/* You can add global styles to this file, and also import other style files */

@import url("assets/css/custom.css");
@import '~bootstrap/dist/css/bootstrap.min.css';
html,
body {
    height: 100%;
}

body {
    margin: 0;
}

th.check-all {
    width: 3em;
    padding-left: .65em;
}

div.sidebar .row {
    min-height: 10em;
    border: 1px solid #656565;
    margin: 1.5em 0;
}

*::-webkit-scrollbar {
    width: 0.2em;
}

.mat-drawer:hover::-webkit-scrollbar {
    width: .8em;
    cursor: pointer;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.5px solid slategrey;
}

.img-profile {
    max-width: 80px;
}


/* table {
    margin-bottom: 0;
    border-bottom: 3px solid #e8e8e8;
} */


/* tr th,
thead th,
th.mat-header-cell {
    border-top: 1px solid #ddd;
    background-color: #eeeeee;
    vertical-align: middle !important;
    font-weight: bold;
    color: #000000;
    padding: 8px;
} */

th.mat-column-select,
td.mat-column-select {
    flex: 0 0 60px;
    padding-left: 20px !important;
}

th.link,
.clickable {
    cursor: pointer;
}


/* tr.mat-header-row {
    height: auto !important;
    vertical-align: middle;
} */

tr {
    vertical-align: middle;
}

tr.highlighted,
mat-row.highlighted {
    background-color: #afafaf !important;
    color: white;
}

tr.highlighted td,
mat-row.highlighted mat-cell {
    color: white;
}

td {
    vertical-align: middle !important;
}


/* .no-wrap {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.table-controls {
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.table-controls ul {
    padding: 0px;
    margin: 0 -15px;
}

.table-controls ul li {
    display: inline-block;
    font-size: 13px;
    border-right: 1px solid #E8E8E8;
    padding: 10px;
    margin-left: -4px;
}

.loading-indicator {
    width: 100%;
    height: 100%;
    text-align: center;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.mat-cell {
    padding: 5px;
    font-size: 13px !important;
}

button.btn-refresh {
    background: transparent;
    outline: none;
    border: 0;
}

button.mat-menu-item.active {
    background-color: grey;
}

.mat-form-field {
    font-size: 14px;
}

.mat-card-with-toolbar mat-card {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.mat-column-sex {
    text-transform: capitalize;
}

a.mat-list-item.active {
    font-weight: bold;
}

a:hover,
a:focus,
a:active,
a:visited {
    font-weight: bolder;
}

a.disabled {
    opacity: 0.5;
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}

mat-row:nth-child(odd) {
    background-color: #f9f9f9;
    ;
}

a.mat-tab-link {
    text-decoration: none;
}

a.mat-tab-link .mat-icon {
    margin-right: 0.2em;
}

.mat-expansion-panel-header {
    padding: 0 16px !important;
}

div.header-row {
    margin-top: 1em;
}

.inside-header {
    margin-left: 0;
    margin-right: 0;
}

.inside-header .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
}

.inside-header .mat-full-width {
    width: 100%;
}

.mat-full-width {
    width: 100%;
}

@media only screen and (min-width: 992px) {
    .inside-header .mat-full-width {
        width: 90%;
    }
}

div.top-margin {
    margin-top: 2em;
}

.margin-top-10 {
    margin-top: 10px;
}

.flex-spacer {
    flex: 1 1 auto;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-top: 10px;
}

.margin-bottom-20 {
    margin-top: 20px;
}

.spacer {
    flex: 1 1 auto;
}

.flex-space {
    flex: 1 1 auto;
}

.mat-column-action {
    text-align: center;
    font-size: 11px;
    max-width: 110px;
}

.mat-column-action a {
    font-size: 12px;
    padding-right: 5px;
}

.mat-column-action .mat-icon-button {
    width: 20px;
}

.mat-column-action .fas {
    font-size: 12px;
    /* font-weight: 200; */
}

.cursor-pointer {
    cursor: pointer;
}

.modal-action-spacer {
    display: flex;
    justify-content: space-between;
}

.right-aligned-header>.mat-content {
    justify-content: space-between;
}

.mat-content>mat-panel-title,
.mat-content>mat-panel-description {
    flex: 0 0 auto;
}

.mat-column-select {
    max-width: 70px;
}

.details p {
    margin: 1px;
}

.details label {
    min-width: 25%;
    margin-right: 1px;
    font-weight: normal;
}

div.responsive-table {
    overflow-x: auto;
    width: 100%;
}

.profile-photo {
    max-width: 200px;
    /* border-radius: 50%; */
    width: 100%;
    margin: .8em auto;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: 5px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}

.small-heading {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
}

.full-width {
    width: 100%;
}

.staff-message {
    color: red;
    font-weight: bold;
}

.mat-icon-holder {
    border-radius: 3px;
    padding: 15px;
    margin-top: -40px !important;
    margin-right: 15px;
    float: left;
    color: white;
}

.capitalize {
    text-transform: capitalize;
}

nav {
    margin-bottom: 10px;
}

.mat-list-item,
.mat-list-option {
    height: 48px !important;
}

.main-menu-items {
    height: 48px !important;
    padding: 0 16px !important;
    font-size: 13px;
}

a.mat-list-item {
    /* padding-left: 10px !important; */
}

.menu-list-items {
    padding: 0 !important;
    font-size: 13px !important;
}