@import '~@angular/material/theming';
@import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
///
// Define the default theme (same as the example above).
@include mat-core();
$custom-primary: mat-palette($mat-light-green, 800);
$custom-accent: mat-palette($mat-lime, 800);
$custom-orange: mat-palette($mat-orange, 300);
$custom-info: mat-palette($mat-lime, 600);
$custom-warn: mat-palette($mat-red, 800);
$custom-highlight: mat-palette($mat-blue-gray, 50);
// create theme (use mat-dark-theme for themes with dark backgrounds)
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn);
@include angular-material-theme($custom-theme);
// Export theme colors
$primary: mat-color($custom-primary);
$accent: mat-color($custom-accent);
$warn: mat-color($custom-warn);
@include mat-datetimepicker-theme($custom-theme);

a.mat-list-item.active {
    color: #fff !important;
    background: linear-gradient(14deg, mat-color($custom-primary) 0%, mat-color($custom-primary)) !important;
    -webkit-box-shadow: 2px 2px 5px 0 mat-color($custom-primary) !important;
    box-shadow: 2px 2px 5px 0 mat-color($custom-primary);
    border-radius: 4px;
}

.main-menu-items:hover {
    font-weight: bold !important;
    color: mat-color($custom-primary) !important;
}

.icon-holder {
    background-color: mat-color($custom-primary) !important;
}

a.mat-list-item.active:hover {
    color: mat-color($custom-primary) !important;
    background: #fff !important;
}

// .mat-tab-label:focus, .mat-tab-nav-bar.mat-primary .mat-tab-link:focus
.mat-tab-label-active {
    color: #fff !important;
    background-color: mat-color($custom-primary) !important;
    opacity: 1 !important;
    border-radius: 4px;
}

.mat-tab-link:not(.mat-tab-label-active):focus {
    color: #fff !important;
    background: none !important;
    border-radius: 4px;
}

::ng-deep.mat-tab-label.mat-tab-label-active {
    opacity: 1;
}

// .mat-tab-label-active {
//     color: #fff !important;
//     background-color: mat-color($custom-primary) !important;
//     border-radius: 4px;
//     border-bottom: none;
//     opacity: 1;
// }
.theme-border-color {
    border-color: mat-color($custom-primary) !important;
}

.mat-background-primary {
    background-color: mat-color($custom-primary);
}

.mat-background-accent {
    background-color: mat-color($custom-accent);
}

.mat-background-info {
    background-color: mat-color($custom-info);
}

.mat-higlight {
    background-color: mat-color($custom-highlight);
}

.mat-background-warn {
    background-color: mat-color($custom-warn);
}

a {
    color: mat-color($custom-primary);
}

footer a,
.highlighted a {
    color: mat-color($custom-primary) !important;
}

// .toolbar-notifications {
//     background-color: mat-color($custom-primary) !important;
// }